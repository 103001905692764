<template>
  <div class="inner-section">
      <card>
        <template v-slot:searchHeaderTitle>
          <h4 class="card-title">{{ $t('globalTrans.approve') }}</h4>
        </template>
        <b-container fluid>
          <b-row>
              <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                <b-overlay :show="loading">
                  <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset">
                    <b-row>
                      <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                        <ValidationProvider name="service_id" vid="garden_id">
                            <b-form-group
                              label-for="garden_id"
                              slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('teaGardenConfig.select_garden') }}
                            </template>
                            <b-form-select
                                plain
                                v-model="formData.garden_id"
                                :options="masterTeaGardenGenInfoList"
                                id="garden_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                disabled
                            >
                              <template v-slot:first>
                                <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                            </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                        <ValidationProvider name="service_id" vid="service_id">
                            <b-form-group
                              label-for="service_id"
                              slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('teaGardenConfig.service_name') }}
                            </template>
                            <b-form-select
                                plain
                                v-model="formData.service_id"
                                :options="serviceList"
                                id="service_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                disabled
                            >
                              <template v-slot:first>
                                <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                            </b-form-group>
                        </ValidationProvider>
                      </b-col>
                    </b-row>
                    <b-row>
                        <!-- -----------------Add More Start------------------- -->
                        <div class="table-wrapper table-responsive">
                         <!-- <pre>{{ formData.details }}</pre> -->
                          <table class="table table-striped table-hover table-bordered">
                            <b-thead>
                              <tr>
                                <b-th class="text-center" width="10%">{{ $t('globalTrans.sl_no') }}</b-th>
                                <b-th class="text-center" width="20%">{{ $t('teaGardenBtriService.planting_material') }}</b-th>
                                <b-th class="text-center" width="20%">{{ $t('teaGardenBtriService.clone_variety') }}</b-th>
                                <b-th class="text-center">{{ $t('globalTrans.price') }}</b-th>
                                <b-th class="text-center">{{ $t('globalTrans.quantity') }}</b-th>
                                <b-th class="text-center" width="10%">{{ $t('globalTrans.tot_price') }}</b-th>
                                <b-th class="text-center">{{ $t('globalTrans.stock') }}</b-th>
                                <b-th class="text-center" width="20%">{{ $t('teaGardenBtriService.approve_qty') }}</b-th>
                                <b-th class="text-center" width="20%">{{ $t('teaGardenBtriService.approve_tot_price') }}</b-th>
                              </tr>
                            </b-thead>
                            <b-tbody>
                              <template v-if="formData.details && formData.details.length">
                                  <b-tr v-for="(item, index) in formData.details" :key="index">
                                      <b-td class="text-center">{{ $n(index+1) }}</b-td>
                                      <b-td class="text-center">{{ getMatName(item.planting_material_id) }}</b-td>
                                      <b-td class="text-center">{{ getCloneName(item.clone_name_id) }}</b-td>
                                      <b-td class="text-center">{{ $n(item.price) }}</b-td>
                                      <b-td class="text-center"> {{ $n(item.qty) }}</b-td>
                                      <b-td class="text-center">{{ $n(item.total_price) }}</b-td>
                                      <b-td class="text-center">{{ $n(item.clone_quantity.stock_quantity) }}</b-td>
                                      <b-td class="text-center">
                                        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                          <ValidationProvider name="service_id" vid="service_id" rules="required">
                                              <b-form-group
                                                label-for="service_id"
                                                slot-scope="{ valid, errors }"
                                              >
                                              <b-form-input
                                                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                id="beficiary_count"
                                                v-model="item.approved_qnty"
                                                @keyup="approvalCalculation(index)"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                              <div class="invalid-feedback">
                                                  {{ errors[0] }}
                                              </div>
                                              </b-form-group>
                                          </ValidationProvider>
                                        </b-col>
                                      </b-td>
                                      <b-td class="text-center">{{ item.approved_tot_price > 0 ? $n(item.approved_tot_price) : $n(0) }}</b-td>
                                  </b-tr>
                                  <b-tr>
                                    <b-td class="text-right" colspan="4">{{ $t('globalTrans.grand_total') }}</b-td>
                                    <b-td class="text-center" >{{ $n(formData.total_qty) }}</b-td>
                                    <b-td class="text-center">{{ $n(formData.total_price) }}</b-td>
                                    <b-td class="text-center" ></b-td>
                                    <b-td class="text-center" >{{ formData.tot_approved_qty > 0 ? $n(formData.tot_approved_qty) : $n(0) }}</b-td>
                                    <b-td class="text-center">{{ formData.tot_approved_price > 0 ? $n(formData.tot_approved_price) : $n(0) }}</b-td>
                                  </b-tr>
                              </template>
                              <template v-else>
                                <b-tr>
                                  <b-td colspan="7" align="center">{{ $t('globalTrans.noDataFound') }}</b-td>
                                </b-tr>
                              </template>
                            </b-tbody>
                          </table>
                        </div>
                        <!-- -----------------Add More End--------------------- -->
                    </b-row>
                    <b-row class="text-right mb-3">
                        <b-col>
                          <b-button type="submit" variant="primary" class="mr-2">{{$t('globalTrans.save')}}</b-button>

                            <!-- <b-button type="submit" variant="success" class="mr-2 btn-sm">{{ saveBtnName }}</b-button> -->
                            <router-link :to="{ path: '/tea-garden-service/btri/service-app-list'}" class="btn btn-danger btn-sm">
                                {{ $t('globalTrans.cancel') }}
                            </router-link>
                        </b-col>
                    </b-row>
                  </b-form>
                </b-overlay>
              </ValidationObserver>
          </b-row>
        </b-container>
      </card>
  </div>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { approveStore, btriServiceEdit } from '../../../api/routes'

export default {
name: 'Form',
props: ['id'],
data () {
  return {
      valid: null,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      errors: [],
      loading: false,
      formData: {
        tot_approved_qty: 0,
        tot_approved_price: 0,
        details: []
      },
      currentStock: 0
  }
},
created () {
  if (this.$route.query.id) {
    this.getFormData()
  }
},
computed: {
  serviceList: function () {
    return this.$store.state.TeaGardenService.commonObj.masterServiceList.filter(item => [20, 21].includes(parseInt(item.value)))
  },
  masterCloneNameList: function () {
    return this.$store.state.TeaGardenService.commonObj.masterCloneNameList
  },
  masterTeaGardenGenInfoList: function () {
    return this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList
  }
},

methods: {
  async getFormData () {
    this.loading = true
    this.$store.dispatch('mutateCommonProperties', { loading: true })
    let result = null
    const loadingState = { loading: false, listReload: false }
    result = await RestApi.getData(teaGardenServiceBaseUrl, `${btriServiceEdit}/${this.$route.query.id}`)
    loadingState.listReload = true
    this.$store.dispatch('mutateCommonProperties', loadingState)
    if (result.success) {
      this.formData = result.data
    } else {
      this.$toast.success({
        title: this.$t('globalTrans.success'),
        message: 'Clone Price not Found!!!',
        color: '#D6E09B'
      })
    }
    this.loading = false
  },

  async saveUpdate () {
    this.loading = true
    this.$store.dispatch('mutateCommonProperties', { loading: true })
    let result = null
    const loadingState = { loading: false, listReload: false }
    result = await RestApi.postData(teaGardenServiceBaseUrl, `${approveStore}/${this.$route.query.id}`, this.formData)
    loadingState.listReload = true
    this.$store.dispatch('mutateCommonProperties', loadingState)
    if (result.success) {
      this.$store.dispatch('LicenseRegistrationService/mutateCommonObj', { hasDropdownLoaded: false })
      this.$toast.success({
        title: this.$t('globalTrans.success'),
        message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
        color: '#D6E09B'
      })
      this.$router.push({ path: '/tea-garden-service/btri/service-app-list' })
    } else {
      this.$refs.form.setErrors(result.errors)
    }
    this.loading = false
  },
  approvalCalculation (index) {
    const obj = this.formData.details[index]
    this.currentStock = obj.clone_quantity.stock_quantity

    // current stock update
    this.formData.details.map((item, index2) => {
      if (item.clone_name_id === obj.clone_name_id && index2 !== index) {
        this.currentStock -= item.approved_qnty !== null ? item.approved_qnty : 0
      }
    })

    if ((parseInt(obj.approved_qnty) > obj.qty) || (parseInt(obj.approved_qnty) > this.currentStock)) {
      const actualQty = obj.qty >= this.currentStock ? this.currentStock : obj.qty
      this.$nextTick(() => {
        obj.approved_qnty = parseInt(actualQty)
        obj.approved_tot_price = parseInt(obj.price) * obj.approved_qnty
        this.totPriceQtyCount()
      })
    } else {
      obj.approved_tot_price = parseInt(obj.price) * parseInt(obj.approved_qnty)
      this.totPriceQtyCount()
    }
  },
  totPriceQtyCount () {
    let totApprovedQty = 0
    let totApprovedPrice = 0
    this.formData.details.map(item => {
      totApprovedQty += item.approved_qnty !== null ? parseInt(item.approved_qnty) : 0
      totApprovedPrice += item.approved_tot_price !== null ? parseInt(item.approved_tot_price) : 0
    })
    this.formData = Object.assign({}, this.formData, {
      tot_approved_qty: totApprovedQty,
      tot_approved_price: totApprovedPrice
    })
  },
  getMatName (id) {
    const data = this.$store.state.TeaGardenService.commonObj.plantingMaterialList.find(item => item.value === id)
    if (data) {
      return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
    }
  },
  getCloneName (id) {
    const data = this.masterCloneNameList.find(item => item.value === id)
    if (data) {
      return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
    }
  }
  }
}
</script>
